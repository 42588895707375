/**
 * @generated SignedSource<<04b04e2520ea6263f7d853e66086f858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PermissionType = "ADMIN" | "NONE" | "READ" | "WRITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RepositoryCard_repository$data = {
  readonly id: string;
  readonly lastDefaultBranchBuild: {
    readonly branch: string;
    readonly changeMessageTitle: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BuildBranchNameChipNew_build" | "BuildStatusChipNew_build" | "CreatedTime_build" | "Hash_build">;
  } | null;
  readonly name: string;
  readonly owner: string;
  readonly platform: string;
  readonly viewerPermission: PermissionType;
  readonly " $fragmentType": "RepositoryCard_repository";
};
export type RepositoryCard_repository$key = {
  readonly " $data"?: RepositoryCard_repository$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepositoryCard_repository">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepositoryCard_repository",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerPermission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Build",
      "kind": "LinkedField",
      "name": "lastDefaultBranchBuild",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "branch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "changeMessageTitle",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreatedTime_build"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Hash_build"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuildStatusChipNew_build"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuildBranchNameChipNew_build"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Repository",
  "abstractKey": null
};
})();

(node as any).hash = "cec742ec73bbf4e926a605386f53b283";

export default node;
