/**
 * @generated SignedSource<<c0f527d589d15b7b5e922af73bbe3e96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskCommandStatus = "ABORTED" | "EXECUTING" | "FAILURE" | "SKIPPED" | "SUCCESS" | "UNDEFINED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type colors_TaskCommand$data = {
  readonly status: TaskCommandStatus;
  readonly " $fragmentType": "colors_TaskCommand";
};
export type colors_TaskCommand$key = {
  readonly " $data"?: colors_TaskCommand$data;
  readonly " $fragmentSpreads": FragmentRefs<"colors_TaskCommand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "colors_TaskCommand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "TaskCommand",
  "abstractKey": null
};

(node as any).hash = "b0ffa427a767983b32df02c04a436349";

export default node;
