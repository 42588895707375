/**
 * @generated SignedSource<<d2913c92c69d258cb5b20cb517dad5ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActiveRepositoriesDrawerQuery$variables = {};
export type ActiveRepositoriesDrawerQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly webPushServerKey: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccountInformation_viewer">;
  } | null;
};
export type ActiveRepositoriesDrawerQuery = {
  response: ActiveRepositoriesDrawerQuery$data;
  variables: ActiveRepositoriesDrawerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webPushServerKey",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveRepositoriesDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountInformation_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ActiveRepositoriesDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarURL",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c619b63f6cd13cd92446a7940e349805",
    "id": null,
    "metadata": {},
    "name": "ActiveRepositoriesDrawerQuery",
    "operationKind": "query",
    "text": "query ActiveRepositoriesDrawerQuery {\n  viewer {\n    id\n    webPushServerKey\n    ...AccountInformation_viewer\n  }\n}\n\nfragment AccountInformation_viewer on User {\n  avatarURL\n}\n"
  }
};
})();

(node as any).hash = "386301f3f52babde390ad624bd157610";

export default node;
