/**
 * @generated SignedSource<<ffb3bdfe7302069c68bdb70738284672>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Hash_build$data = {
  readonly changeIdInRepo: string;
  readonly " $fragmentType": "Hash_build";
};
export type Hash_build$key = {
  readonly " $data"?: Hash_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"Hash_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Hash_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changeIdInRepo",
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "9093431005759ec33f2b66f4b7d7b318";

export default node;
