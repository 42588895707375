/**
 * @generated SignedSource<<a6ea46c2bf3469b571666e5314e93e8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteWebPushConfigurationInput = {
  clientMutationId: string;
  endpoint: string;
};
export type ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation$variables = {
  input: DeleteWebPushConfigurationInput;
};
export type ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation$data = {
  readonly deleteWebPushConfiguration: {
    readonly clientMutationId: string;
  };
};
export type ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation = {
  response: ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation$data;
  variables: ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteWebPushConfigurationPayload",
    "kind": "LinkedField",
    "name": "deleteWebPushConfiguration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5dd8031aabc0744e86ce19d9db9d15c9",
    "id": null,
    "metadata": {},
    "name": "ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation",
    "operationKind": "mutation",
    "text": "mutation ActiveRepositoriesDrawerDeleteWebPushConfigurationMutation(\n  $input: DeleteWebPushConfigurationInput!\n) {\n  deleteWebPushConfiguration(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d84ec0bb3a23c87290e0d89600a36d4";

export default node;
