/**
 * @generated SignedSource<<123b41a7732fccae756ee820275a09a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationLevel = "ERROR" | "INFO" | "WARNING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type colors_Notification$data = {
  readonly level: NotificationLevel;
  readonly " $fragmentType": "colors_Notification";
};
export type colors_Notification$key = {
  readonly " $data"?: colors_Notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"colors_Notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "colors_Notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": null
};

(node as any).hash = "bfb2ceb0d171551f3dea5416dc67b4f0";

export default node;
