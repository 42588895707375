/**
 * @generated SignedSource<<36bcb128ab1468995b0f2a4c0ad26682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BuildStatus = "ABORTED" | "COMPLETED" | "CREATED" | "ERRORED" | "EXECUTING" | "FAILED" | "NEEDS_APPROVAL" | "TRIGGERED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BuildStatusChipNew_build$data = {
  readonly hasPausedTasks: boolean;
  readonly id: string;
  readonly status: BuildStatus;
  readonly " $fragmentType": "BuildStatusChipNew_build";
};
export type BuildStatusChipNew_build$key = {
  readonly " $data"?: BuildStatusChipNew_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildStatusChipNew_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildStatusChipNew_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPausedTasks",
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "5844d616d8b1656a6cce5dbc673da6c7";

export default node;
