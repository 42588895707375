/**
 * @generated SignedSource<<25c7971358989f386c997ed70985180d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewerTopRepositoriesQuery$variables = {};
export type ViewerTopRepositoriesQuery$data = {
  readonly viewer: {
    readonly topActiveRepositories: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RepositoryCard_repository">;
    }>;
  } | null;
};
export type ViewerTopRepositoriesQuery = {
  response: ViewerTopRepositoriesQuery$data;
  variables: ViewerTopRepositoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "owner",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewerTopRepositoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "topActiveRepositories",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RepositoryCard_repository"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ViewerTopRepositoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "topActiveRepositories",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerPermission",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Build",
                "kind": "LinkedField",
                "name": "lastDefaultBranchBuild",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "branch",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "changeMessageTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buildCreatedTimestamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "changeIdInRepo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPausedTasks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Repository",
                    "kind": "LinkedField",
                    "name": "repository",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00fc43b501e68dfd8b6b571c9bb8d7bc",
    "id": null,
    "metadata": {},
    "name": "ViewerTopRepositoriesQuery",
    "operationKind": "query",
    "text": "query ViewerTopRepositoriesQuery {\n  viewer {\n    topActiveRepositories {\n      id\n      ...RepositoryCard_repository\n    }\n    id\n  }\n}\n\nfragment BuildBranchNameChipNew_build on Build {\n  id\n  branch\n  tag\n  repository {\n    id\n    owner\n    name\n  }\n}\n\nfragment BuildStatusChipNew_build on Build {\n  id\n  status\n  hasPausedTasks\n}\n\nfragment CreatedTime_build on Build {\n  buildCreatedTimestamp\n}\n\nfragment Hash_build on Build {\n  changeIdInRepo\n}\n\nfragment RepositoryCard_repository on Repository {\n  id\n  platform\n  owner\n  name\n  viewerPermission\n  lastDefaultBranchBuild {\n    id\n    branch\n    changeMessageTitle\n    ...CreatedTime_build\n    ...Hash_build\n    ...BuildStatusChipNew_build\n    ...BuildBranchNameChipNew_build\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c744f0e117d1eeda56e41a069483aa0";

export default node;
