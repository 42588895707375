/**
 * @generated SignedSource<<7f5741460a822e0890b39648e35290a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildBranchNameChipNew_build$data = {
  readonly branch: string;
  readonly id: string;
  readonly repository: {
    readonly id: string;
    readonly name: string;
    readonly owner: string;
  };
  readonly tag: string | null;
  readonly " $fragmentType": "BuildBranchNameChipNew_build";
};
export type BuildBranchNameChipNew_build$key = {
  readonly " $data"?: BuildBranchNameChipNew_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildBranchNameChipNew_build">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildBranchNameChipNew_build",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Repository",
      "kind": "LinkedField",
      "name": "repository",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};
})();

(node as any).hash = "bdbd2d60bc476b9d679d3203b0794ae7";

export default node;
