/**
 * @generated SignedSource<<11cc6d19c05e7c79e71de059ba5d3846>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatedTime_build$data = {
  readonly buildCreatedTimestamp: number;
  readonly " $fragmentType": "CreatedTime_build";
};
export type CreatedTime_build$key = {
  readonly " $data"?: CreatedTime_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatedTime_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatedTime_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildCreatedTimestamp",
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "8d1ec61c5ce8b6ed7a6f0f5118bc3739";

export default node;
